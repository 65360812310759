import React from 'react';
import arrowRight from 'src/assets/svgs/icon-right-chevron.svg';
import { Link } from 'react-router-dom';
import { Spinner } from 'src/skins/common/components';
import PropTypes from 'prop-types';

export const ContentfulModalNews = ({latestNews, isLoadingNews, newsContent}) => {

  return (
    <>
      {isLoadingNews ? (
        <center>
          <Spinner />
        </center>
      ) : (
        <>
          {latestNews?.image?.fields && (
            <img
              className="img-placeholder"
              src={`https:${latestNews?.image?.fields?.file?.url}`}
              alt={latestNews?.image?.fields?.title || ''}
            />
          )}

          <Link to="/dashboard/news" style={{ textDecoration: 'none' }}>
            <h4 className="title">{latestNews?.title || ''}</h4>
          </Link>
          <div className="content" dangerouslySetInnerHTML={{ __html: newsContent }} />
          <Link className="read-more" to="/dashboard/news">
            <span> Read more</span>
            <img src={arrowRight} alt="arrow" />
          </Link>
        </>
      )}
    </>
  );
};

ContentfulModalNews.propTypes={
  latestNews: PropTypes.object,
  isLoadingNews: PropTypes.bool,
  newsContent: PropTypes.any
};