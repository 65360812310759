import Auth from './pages/auth/Auth';

// Dashboard Pages
import Dashboard from './pages/dashboard/Dashboard';
import Wallet from './pages/dashboard/Wallet';
import TransactionHistory from './pages/dashboard/TransactionHistory';

// News Page
import News from './pages/dashboard/News';

// Dashboard Transaction History Pages
import TransactionHistoryDetails from './pages/dashboard/TransactionHistoryDetails';

// Dashboard Transfer Pages
import Transfer from './pages/dashboard/Transfer';
import P2PTransfer from './pages/dashboard/transfer/P2PTransfer';
import BankTransfer from './pages/dashboard/transfer/BankTransfer';

// Dashboard Withdrawal Pages
import ThirdParty from './pages/dashboard/withdrawal/ThirdParty';

// Dashboard Bills Payment Pages
import Bills from './pages/dashboard/Bills';
import BuyAirtime from './pages/dashboard/bills/BuyAirtime';
import BillsTvCables from './pages/dashboard/bills/BIllsTvCables';
import BillsElectricity from './pages/dashboard/bills/BillsElectricity';
import BuyMobileData from './pages/dashboard/bills/BuyMobileData';
import BillsBeneficiaries from './pages/dashboard/bills/BillsBeneficiaries';

// Dashboard Collections Pages
import Collections from './pages/dashboard/Collections';
import ImcCollection from './pages/dashboard/collections/ImcCollection';
import HtbCollection from './pages/dashboard/collections/HtbCollection';

// My Devices
import Devices from './pages/dashboard/Devices';

// Dashboard Settings Page
import Settings from './pages/dashboard/Settings';
import { Terms, PrivacyPolicy, Profile, IdVerification } from './pages/dashboard/settings/';

// Dashboard Support Pages
import { NewSupport, SupportDetails, SupportDashboard } from './pages/dashboard/support';

// Dashboard Aggregator Pages
import {
  AggregatorDashboard,
  AggregatorTransactions,
  AggregatorTransactionDetails,
} from './pages/dashboard/aggregator';

export const marsRoutes = [
  // Auth Routes
  { name: 'auth', path: '/auth', component: Auth, isAuth: false },

  // Dashboard Pages
  { name: 'dashboard', path: '/dashboard', component: Dashboard, isAuth: true, exact: true },

  // News Pages
  { name: 'news', path: '/dashboard/news', component: News, isAuth: true, exact: true },

  // Wallet Route
  { name: 'wallet', path: '/dashboard/wallet', component: Wallet, isAuth: true, exact: true },

  // Devices Route
  { name: 'devices', path: '/dashboard/devices', component: Devices, isAuth: true, exact: true },

  // Transaction History Route
  {
    name: 'transactionHistory',
    path: '/dashboard/transactions',
    component: TransactionHistory,
    isAuth: true,
    exact: true,
  },
  {
    name: 'TransactionHistoryDetails',
    path: '/dashboard/transactions/:ref',
    component: TransactionHistoryDetails,
    isAuth: true,
    exact: true,
  },

  // // Settings Route
  { name: 'privacy', path: '/dashboard/settings/privacy', component: PrivacyPolicy, isAuth: true, exact: true },
  { name: 'terms', path: '/dashboard/settings/terms', component: Terms, isAuth: true, exact: true },
  { name: 'profile', path: '/dashboard/settings/profile', component: Profile, isAuth: true, exact: true },
  {
    name: 'idVerification',
    path: '/dashboard/settings/profile/verification',
    component: IdVerification,
    isAuth: true,
    exact: true,
  },
  { name: 'settings', path: '/dashboard/settings', component: Settings, isAuth: true, exact: true },

  // Transfer Routes
  { name: 'transfer', path: '/dashboard/transfer', component: Transfer, isAuth: true },
  { name: 'transferP2P', path: '/dashboard/transfer/peer', component: P2PTransfer, isAuth: true },
  { name: 'transferBank', path: '/dashboard/transfer/bank', component: BankTransfer, isAuth: true },

  // Withdrawal Routes
  { name: 'ThirdParty', path: '/dashboard/withdrawal/third-party', component: ThirdParty, isAuth: true },

  // Bills Route
  { name: 'bills', path: '/dashboard/bills', component: Bills, isAuth: true },
  { name: 'beneficiaries', path: '/dashboard/bills/beneficiaries', component: BillsBeneficiaries, isAuth: true },
  { name: 'airtime', path: '/dashboard/bills/airtime', component: BuyAirtime, isAuth: true },
  { name: 'data', path: '/dashboard/bills/data', component: BuyMobileData, isAuth: true },
  { name: 'tv-cables', path: '/dashboard/bills/tv-cables/:type', component: BillsTvCables, isAuth: true },
  { name: 'electricity', path: '/dashboard/bills/electricity/:type', component: BillsElectricity, isAuth: true },

  // Collections Route
  { name: 'collections', path: '/dashboard/collections', component: Collections, isAuth: true },
  { name: 'imc-collection', path: '/dashboard/collections/imc', component: ImcCollection, isAuth: true },
  { name: 'htb-collection', path: '/dashboard/collections/htb', component: HtbCollection, isAuth: true },

  { name: 'cash', path: '/dashboard/cash', component: Dashboard, isAuth: true },
  { name: 'loan', path: '/dashboard/loan', component: Dashboard, isAuth: true },

  // Support Routes
  { name: 'support', path: '/dashboard/support', component: SupportDashboard, isAuth: true, exact: true },
  { name: 'newSupport', path: '/dashboard/support/new', component: NewSupport, isAuth: true, exact: true },
  { name: 'supportDetails', path: '/dashboard/support/:id', component: SupportDetails, isAuth: true, exact: true },

  // Aggregator Routes
  { name: 'aggregator', path: '/dashboard/aggregator', component: AggregatorDashboard, isAuth: true, exact: true },
  {
    name: 'aggregatorTransactions',
    path: '/dashboard/aggregator/transactions/:id',
    component: AggregatorTransactionDetails,
    isAuth: true,
    exact: true,
  },
  {
    name: 'aggregatorTransactionDetails',
    path: '/dashboard/aggregator/transactions',
    component: AggregatorTransactions,
    isAuth: true,
    exact: true,
  },
];
