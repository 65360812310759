/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { contentfulUtils } from 'src/utils';
import { formatAMPM } from 'src/utils/helpers';
import { Spinner } from 'src/skins/common/components';

export const ContentfulNews = () => {
  const [latestNews, setLatestNews] = useState({});
  const [contentInfo, setContentInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const LATEST_NEWS = process.env.REACT_APP_LATEST_NEWS_ID;
    // declare the term fetching function
    const fetchLatestNews = async () => {
      const { fields, sys } = await contentfulUtils.client.getEntry(LATEST_NEWS);
      setLatestNews(fields);
      setContentInfo(sys);
      setIsLoading(false);
    };
    // call the function
    // eslint-disable-next-line no-console
    fetchLatestNews().catch(console.error);
  }, []);

  let newsContent = contentfulUtils.formatRichText(latestNews?.content);
  newsContent = newsContent?.replace(/(?:\r\n|\r|\n)/g, '<br />');

  const dateCreated = (contentInfo && contentInfo?.createdAt?.substring(0, 10)) || '';
  const timeCreated = formatAMPM(new Date(contentInfo?.createdAt));

  //if content is not published
  if (Object.keys(latestNews).length === 0) {
    return <p>No data</p>;
  }

  return (
    <>
      {isLoading ? (
        <center>
          <Spinner />
        </center>
      ) : (
        <>
          {latestNews?.image?.fields && (
            <img
              className="img-placeholder"
              src={`https:${latestNews?.image?.fields?.file?.url}`}
              alt={latestNews?.image?.fields?.title || ''}
            />
          )}
          <h4 className="title">{latestNews?.title || ''}</h4>
          <p className="created">
            {dateCreated} {timeCreated}
          </p>
          <div className="content" dangerouslySetInnerHTML={{ __html: newsContent }} />
        </>
      )}
    </>
  );
};
