/* eslint-disable no-console */
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

// Initialize the client
const contentful = require('contentful');
const initContentfulClient = () => {
  try {
    return contentful.createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID || '',
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '',
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return {};
  }
};

export const client = initContentfulClient();

// convert /n to br in rich-text-html-renderer
export const formatRichText = (document) => {
  const options = {};
  return documentToHtmlString(document, options);
};

export const contentfulUtils = {
  client,
  formatRichText,
};

export default contentfulUtils;
