import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { infoAlert } from 'src/store/actions/alertActions';
import { DashboardLayout } from 'src/skins/pluto/components/layouts';
import { Card, Spinner } from 'src/skins/common/components';
import { Icon } from 'src/skins/common/icon';
import { getUserWallet, gotoYawo } from 'src/store/actions/userActions';
import { formatCurrency, copyToClipboard } from 'src/utils';
import Banner from 'src/skins/common/components/Banner';
import { ConfirmActionModal, LatestNewsModal } from 'src/skins/pluto/modals';
import { contentfulUtils } from 'src/utils';

import VerifiedMarkIcon from 'src/assets/svgs/icon-verified-mark.svg';
import UserIcon from 'src/assets/svgs/icon-user.svg';

const Wrapper = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .hi-username {
    display: flex;
    align-items: center;

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.75rem;
    }
  }

  .transfer-id {
    font-size: 1rem;
    font-weight: 400;
  }

  .available-balance--wrapper {
    position: relative;
    margin: 1rem 0;
  }

  .available-balance--toggle {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    appearance: none;
    outline: none;
    border: 0;
    color: #ffffff;
    background: transparent;
    cursor: pointer;
  }

  .available-balance {
    background-color: ${props => props.theme.color.primary};
    color: ${props => props.theme.color.light};
    border-radius: 10px;
    padding: 1rem 2rem;

    .title {
      color: rgba(255, 255, 255, 0.8);
      font-size: 1.25rem;
      font-weight: 600;
    }

    .balance {
      margin-top: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  section {
    margin: 1rem 0;
  }

  .possible-actions-grid {
    margin-top: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    margin-left: auto;
    max-width: 100%;

    & > a {
      display: inline-block;
      padding: 0;
      background: transparent;
      color: inherit;
      border: 0;
      outline: none;
      text-align: center;

      & > div {
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      &:focus {
        & > div {
          box-shadow: none;
        }
      }
    }

    .action-card {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5.5rem;
      padding: 0.4rem;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
  .soon {
    padding-left: 0.2rem;
    font-size: 0.5rem;
  }
  .transfer_id {
    display: flex;
    grid-gap: 0.5rem;
    justify-content: space-between;
  }
  .transfer_id > div {
    display: flex;
    grid-gap: 0.5rem;
    cursor: pointer;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Dashboard = () => {
  const [showAvailableBalance, setShowAvailableBalance] = useState(false);
  const toggleAvailableBalance = () => setShowAvailableBalance(!showAvailableBalance);

  const mmo = useSelector(state => state.MMO.mmo);
  const mmoServices = mmo.services ? mmo.services.split(';') : [];
  const { user, wallet, isLoading } = useSelector(state => state.USER);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getUserWallet());
  }, [getUserWallet]);

  const copiedAlert = value => dispatch(infoAlert('Copied: ' + value));

  const [showConfirmToOpenYawoAppModal, setConfirmToOpenYawoAppModal] = useState(false);
  const openConfirmToOpenYawoAppModal = () => setConfirmToOpenYawoAppModal(true);
  const closeConfirmToOpenYawoAppModal = () => setConfirmToOpenYawoAppModal(false);
  const acceptToOpenYawoApp = () => gotoYawo(closeConfirmToOpenYawoAppModal);

  //news
  const [latestNews, setLatestNews] = useState({});
  const [isLoadingNews, setIsLoadingNews] = useState(true);
  const [showLatestNewsModal, setLatestNewsModal] = useState(false);
  const closeLatestNewsModal = () => setLatestNewsModal(false);

  const LATEST_NEWS_ID = process.env.REACT_APP_LATEST_NEWS_ID;
  const fetchLatestNews = async () => {
    const { fields } = await contentfulUtils?.client?.getEntry(LATEST_NEWS_ID);
    setLatestNews(fields);
    setIsLoadingNews(false);
    const hasPublishedNews = Object.keys(fields).length > 0;
    setLatestNewsModal(hasPublishedNews);
  };

  useEffect(() => {
    if (history.location.pathname === '/dashboard') {
      fetchLatestNews()
        .catch(error => {
          console(error);
        })
        .finally(() => setIsLoadingNews(false));
    }
  }, []);

  let newsContent = contentfulUtils.formatRichText(latestNews?.content);
  newsContent = newsContent?.replace(/(?:\r\n|\r|\n)/g, '<br />');

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <div className="hi-username">
            <h2>Hi {`${user.firstName} ${user.lastName}`},</h2>
            <img src={VerifiedMarkIcon} alt="✔" />
          </div>
          <img src={UserIcon} alt="USER" />
        </div>

        <div className="transfer_id">
          <div>
            <p className="transfer-id">Transfer ID: {user.iuwi}</p>
            <Icon name="copy" size="small" onClick={() => copyToClipboard('transferId', copiedAlert)} />
          </div>
          {isLoading.getUserWallet && <Spinner theme="primary" />}
        </div>

        <input id="transferId" type="text" value={user.iuwi} onChange={null} readOnly style={hiddenInputStyle} />

        <div className="available-balance--wrapper">
          <button type="button" className="available-balance--toggle" onClick={toggleAvailableBalance}>
            <Icon size="small" name={showAvailableBalance ? 'eye' : 'eyeOff'} />
          </button>
          <Card elevation={0} className="available-balance">
            <div className="title">Available Balance</div>
            <div className="balance">
              {!isLoading.getUserWallet
                ? showAvailableBalance
                  ? formatCurrency(wallet.walletBalance || 0, 'NGN')
                  : '********'
                : '•••'}
            </div>
          </Card>
        </div>

        {!user.hasPin && (
          <Banner type="warning">
            <p>
              {'Transaction pin not set. '}
              <span className="link" onClick={() => history.push('/dashboard/settings')}>
                Click Here
              </span>
            </p>
          </Banner>
        )}

        <section>
          <h6>Make a transaction</h6>
          <div className="possible-actions-grid">
            <Link to="/dashboard/transfer">
              <Card elevation={0} className="primary action-card">
                Transfer
              </Card>
            </Link>
            <Link to="/dashboard/bills">
              <Card elevation={0} className="primary action-card">
                Bills payment
              </Card>
            </Link>
            {mmoServices.some(x => x === 'clec') && (
              <Link to="/dashboard/collections">
                <Card elevation={0} className="primary action-card">
                  Collections
                </Card>
              </Link>
            )}
            <Link to="/dashboard/devices">
              <Card elevation={0} className="primary action-card">
                My Devices
              </Card>
            </Link>
            {mmoServices.some(x => x === 'yawo') && (
              <>
                <Link to="#" onClick={openConfirmToOpenYawoAppModal}>
                  <Card elevation={0} className="primary action-card">
                    Yawo
                  </Card>
                </Link>
                <ConfirmActionModal
                  isOpen={showConfirmToOpenYawoAppModal}
                  actionTitle="Confirm To Access Yawo"
                  actionMessage="This action takes you to Yawo App"
                  onAccept={acceptToOpenYawoApp}
                  onDecline={closeConfirmToOpenYawoAppModal}
                  closeModal={closeConfirmToOpenYawoAppModal}
                />
              </>
            )}
            <Link to="/dashboard/withdrawal/third-party">
              <Card elevation={0} className="primary action-card">
                Cardless Withdrawal
              </Card>
            </Link>
            {/* <Link to="/dashboard" onClick={() => dispatch(infoAlert('Coming Soon'))}>
							<Card elevation={0} className="primary action-card">
								Cash
							</Card>
						</Link> */}
            {/* <Link to="/dashboard" onClick={() => dispatch(infoAlert('Coming Soon'))}>
							<Card elevation={0} className="primary action-card">
								Insurance
							</Card>
						</Link> */}
            {/* <Link to="/dashboard" onClick={() => dispatch(infoAlert('Coming Soon'))}>
							<Card elevation={0} className="primary action-card">
								Shop
							</Card>
						</Link> */}
          </div>
        </section>
        <LatestNewsModal
          isOpen={showLatestNewsModal}
          closeModal={closeLatestNewsModal}
          latestNews={latestNews}
          isLoadingNews={isLoadingNews}
          newsContent={newsContent}
        />
        <br />

        <section>
          <h6>Self Care</h6>
          <div className="possible-actions-grid">
            <Link to="/dashboard/support">
              <Card elevation={0} className="primary action-card">
                Issues
              </Card>
            </Link>
          </div>
        </section>

        <br />
      </Wrapper>
    </DashboardLayout>
  );
};

const hiddenInputStyle = { opacity: 0, display: 'block', marginBottom: '-2rem' };

export default Dashboard;
