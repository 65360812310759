import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal as AppModal } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import close from 'src/assets/svgs/icon-close.svg';

// Possible backgroundPage
import Dashboard from 'src/skins/venus/pages/dashboard/Dashboard';
import Bills from 'src/skins/venus/pages/dashboard/Bills';
import Collections from 'src/skins/venus/pages/dashboard/Collections';
import Settings from 'src/skins/venus/pages/dashboard/Settings';

const ModalWrapper = styled(AppModal)`
  border: none;
`;

const Wrapper = styled.div`
  @keyframes slideUp {
    0% {
      opacity: 0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
    }

    100% {
      opacity: 1;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .venus--modal {
    position: absolute;
    background-color: ${props => props.theme.color.light};
    border: none;
    width: 100%;
    max-width: 100%;
    min-width: 20rem;
    padding: 0;
    border-radius: 50px 50px 0 0;
    overflow: hidden;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    animation: slideUp 250ms ease 1;
    transition: ease 250ms all;

    @media (pointer: fine) {
      max-width: 400px;
    }

    .venus--modal-header {
      background-color: ${props => props.theme.color.light};
      position: sticky;
      top: 0;
      left: 0;
      height: 2rem;
      width: 100%;
      margin-top: -1rem;
      z-index: 11;

      &:before {
        position: absolute;
        content: '';
        top: -0.5rem;
        left: 50%;
        transform: translateX(-50%);
        height: 1px;
        margin: 1.5rem auto;
        width: 140px;
        background: ${props => props.theme.color.venus.gray || props.theme.color.gray};
      }
    }


  .close-icon {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 1.5rem 0 0;
    img {
      width: 20px;
      cursor: pointer;
      filter: brightness(0) saturate(100%) invert(77%) sepia(9%) saturate(5913%) hue-rotate(307deg) brightness(99%) contrast(85%);
    }
  }

    .venus--modal-content {
      max-height: 85vh;
      overflow: auto;
      padding: 1.5rem;

      &[fullheight] {
        height: 85vh;
      }
    }
  }
`;

/**
 * @description Modal Component
 * @param {ModalProps} props
 */
const VenusModal = props => {
  const history = useHistory();
  const { location } = history;
  const { id, isOpen, closeModal, children, disableBackdropClick, fullheight, backgroundPage, showCloseButton } = props;

  let closeModalWithId = null;
  if (id) {
    closeModalWithId = () => {
      const modal = document.getElementById(`modal-${id}`);
      if (modal) {
        modal.style.transform = 'translate(-50%, 100%)';
      }

      setTimeout(() => {
        window.location.hash = '';
        setTimeout(() => {
          history.replace(location.pathname);
          if (closeModal) closeModal();
          if (modal) {
            modal.style.transform = 'translateX(-50%)';
          }
        }, 100);
      }, 250);
    };
  }

  return (
    <React.Fragment>
      {backgroundPage === 'dashboard' && (
        <div style={{ margin: '-1rem' }}>
          <Dashboard asBackgroundPage={true} />
        </div>
      )}
      {backgroundPage === 'bills' && (
        <div style={{ margin: '-1rem' }}>
          <Bills />
        </div>
      )}
      {backgroundPage === 'collections' && (
        <div style={{ margin: '-1rem' }}>
          <Collections />
        </div>
      )}
      {backgroundPage === 'settings' && (
        <div style={{ margin: '-1rem' }}>
          <Settings />
        </div>
      )}

      <ModalWrapper
        id={id}
        with-id={id ? '' : null}
        open={isOpen}
        onClose={closeModalWithId || closeModal}
        disableBackdropClick={disableBackdropClick}
        style={{ display: id ? (location.hash === `#${id}` ? 'block' : 'none') : 'block' }}
      >
        <React.Fragment>
          <Wrapper>
            <div id={id ? `modal-${id}` : null} className="venus--modal">
              <div className="venus--modal-header"></div>
              {showCloseButton && (
                <div className="close-icon">
                  <img src={close} alt="close" onClick={closeModal} />
                </div>
              )}
              <div className="venus--modal-content" fullheight={fullheight ? '' : null}>
                {children}
              </div>
            </div>
          </Wrapper>
        </React.Fragment>
      </ModalWrapper>
    </React.Fragment>
  );
};

VenusModal.defaultProps = {
  id: null,
  isOpen: false,
  fullheight: false,
  disableBackdropClick: false,
  backgroundPage: '',
  showCloseButton: false,
};

/**
 * Modal PropType Definition
 * @typedef {Object} ModalProps
 * @property {String} id
 * @property {React} children
 * @property {boolean} isOpen
 * @property {boolean} fullheight
 * @property {Function} closeModal
 * @property {boolean} backgroundPage
 * @property {String} showCloseButton
 */
VenusModal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  fullheight: PropTypes.bool,
  closeModal: PropTypes.func,
  children: PropTypes.node,
  disableBackdropClick: PropTypes.bool,
  backgroundPage: PropTypes.string,
  showCloseButton: PropTypes.bool,
};

export { VenusModal };
