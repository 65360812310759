import React from 'react';
import styled from 'styled-components';
import { DashboardLayout } from 'src/skins/jupiter/components/layouts';
import { ContentfulNews } from 'src/skins/common/news/ContentfulNews';
import { Icon } from 'src/skins/common/icon';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .img-placeholder {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .title {
    font-size: 18px;
    line-height: 24px;
    color: ${props => props.theme.color.primary};
  }
  .created {
    color: ${props => props.theme.color.gray};
    margin: 10px 0 15px 0;
  }
  .content > p {
    color: ${props => props.theme.color.jupiter.text || props.theme.color.text};
    font-size: 1.1em;
    line-height: 24px;
    margin-bottom: 20px;
  }
`;

const News = () => {
  const history = useHistory();
  const pageTitle = 'News';

  return (
    <DashboardLayout>
      <Wrapper>
        <div className="header">
          <Icon name="back" onClick={() => history.push('/dashboard')} />
          <h2 style={{ marginLeft: '0.5rem' }}>{pageTitle}</h2>
        </div>
        <br />

        <ContentfulNews />
      </Wrapper>
    </DashboardLayout>
  );
};

export default News;
