import React from 'react';
import { Modal as AppModal } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import close from 'src/assets/svgs/icon-close.svg';

const ModalWrapper = styled(AppModal)`
  border: none;
`;

const Wrapper = styled.div`
  .modal {
    position: absolute;
    background-color: ${props => props.theme.color.light};
    border: none;
    max-width: 20rem;
    min-width: 20rem;
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .close-icon {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    img {
      width: 20px;
      cursor: pointer;
      filter: brightness(0) saturate(100%) invert(77%) sepia(9%) saturate(5913%) hue-rotate(307deg) brightness(99%) contrast(85%);
    }
  }
`;

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-${50}%, -${50}%)`,
  };
}

/**
 * @description Modal Component
 * @param {ModalProps} props
 */
const Modal = props => {
  const { isOpen, closeModal, children, showCloseButton } = props;
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <ModalWrapper open={isOpen} onClose={closeModal} disableBackdropClick={false}>
      <Wrapper>

        <div style={modalStyle} className="modal">
        {showCloseButton && (
                <div className="close-icon">
                  <img src={close} alt="close" onClick={closeModal} />
                </div>
              )}
          {children}
        </div>
      </Wrapper>
    </ModalWrapper>
  );
};

Modal.defaultProps = {
  isOpen: false,
  showCloseButton: false
};

/**
 * Modal PropType Definition
 * @typedef {Object} ModalProps
 * @property {React} children
 * @property {boolean} isOpen
 * @property {Function} closeModal
 * @property {boolean} showCloseButton
 */
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node,
  showCloseButton: PropTypes.bool,
};

export { Modal };
