import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContentfulModalNews } from 'src/skins/common/news/ContentfulModalNews';
import { VenusModal } from 'src/skins/venus/components/common';

const Wrapper = styled.div`
  width: 100%;

  .img-placeholder {
    margin-bottom: 10px;
    width: calc(100% + 3rem);
    margin-left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
    height: 15rem;
    object-position: top;
  }

  .title {
    font-size: 18px;
    line-height: 24px;
    color: ${props => props.theme.color.primary};
  }

  .content > p {
    color: ${props => props.theme.color.text};
    font-size: 1.1em;
    margin: 10px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 18px;
  }
  .read-more {
    display: flex;
    gap: 10px;
    align-items: center;
    color: ${props => props.theme.color.gray};
    &:hover {
      color: ${props => props.theme.color.primary};
    }
    img {
      filter: brightness(0) saturate(100%) invert(77%) sepia(9%) saturate(5913%) hue-rotate(307deg) brightness(99%)
        contrast(85%);
    }
  }
`;

const LatestNewsModal = ({ isOpen, closeModal, latestNews, isLoadingNews, newsContent }) => {
  return (
    <VenusModal isOpen={isOpen} closeModal={closeModal} showCloseButton>
      <Wrapper>
        <ContentfulModalNews latestNews={latestNews} isLoadingNews={isLoadingNews} newsContent={newsContent} />
      </Wrapper>
    </VenusModal>
  );
};

LatestNewsModal.defaultProps = {
  isOpen: false,
  closeModal: null,
};

/**
 * LatestNewsModal PropType Definition
 * @typedef {Object} LatestNewsModalProps
 * @property {boolean} isOpen
 * @property {Function} closeModal
 * @property {any} newsContent
 * @property {Object} latestNews
 * @property {boolean} isLoadingNews
 */
LatestNewsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  latestNews: PropTypes.object,
  isLoadingNews: PropTypes.bool,
  newsContent: PropTypes.any,
};

export { LatestNewsModal };
